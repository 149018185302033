<template>
    <v-row no-gutters class="d-flex justify-start align-center mb-1 px-4" style="height: 24px !important; width: 100%;">
        <v-col class="d-flex justify-start" style="height: 24px !important;">
            <v-tabs :value="tab"
                height="24px"
                @change="setFilterTab($event)"
            >
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab  v-for="item in tabItems" :key="item">
                    <span class="text-capitalize">{{ item }}</span>
                </v-tab>
            </v-tabs>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        tab: { type: Number, required: true },
        tabItems: { type: Array, required: true },
    },

    methods: {
        setFilterTab(e) {
            this.$emit('setFilterTab', e)
        }
    }
}
</script>

<style scoped>
div.v-tab {
    font-size: 12px;
}

div.v-tab.v-tab--active {
    font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
    font-weight: bold;
    color: var(--primary-color);
}

div.v-tab:hover span {
    font-weight: bold;
    color: black;
}
</style>
